import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box } from '@/components/Box/Box';
import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { Accordion } from '@latitude/accordion';
import { Promo } from '@latitude/promo';
import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import { PromoSection } from '../../components/Promo/Promo';
import { Heading4 } from '../../components/Heading/Heading';
import PageData from '../../data/pages/interest-free/business.json';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import FeaturesStaggeredSlider from '../../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import { BREAKPOINT, COLOR, ALIGN } from '../../utils/constants';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Layout from '../../components/layout';
import PartneringBenifits from './_partnering-benefits';
import goodguys from './images/good-guys-logo-new.svg';
import jbhifi from './images/jb-hifi.svg';
import amart from './images/amart.svg';
import apple from './images/apple-logo.svg';
import freedom from './images/freedom-furniture.svg';
import samsung from './images/samsung.svg';
import michaelhill from './images/michael-hill.svg';
import snooze from './images/snooze.svg';
import luxuryescapes from './images/luxury-escapes.svg';
import interestFreeHeroimage from './images/interest-free-hero-banner.webp';
import calloutLeftBg from './images/confetti-left-desktop.svg';
import calloutRightBg from './images/confetti-right-desktop.svg';
import calloutMobileBg from './images/confetti-mobile.svg';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';

const BusinessIFPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'The home of Interest Free shopping',
      description: 'Why Latitude Interest Free options are a real head turner.',
      featureCards: PageData.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/interest-free/business/"
          />
          <title>
            Become an interest free partner | Latitude Interest Free
          </title>
          <meta
            name="description"
            content="We want to be your partner in money. Helping your business grow and creating the best Interest Free shopping experience for your customers."
          />
          <meta name="keywords" content="Latitude Intrestfree" />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <div css="position:relative;background: linear-gradient(128.6deg, #0146AA 0%, #0162ED 100%);">
            <HeroBranded
              transparentBg
              css={`
                background: linear-gradient(128.6deg, #0146aa 0%, #0162ed 100%);
                @media (min-width: ${BREAKPOINT.LG}) {
                  padding: 0;
                }
                @media (max-width: ${BREAKPOINT.SM}) {
                  > div {
                    max-width: 390px;
                  }
                }
                @media (min-width: ${BREAKPOINT.MD}) {
                  > div {
                    > div:first-child {
                      .container {
                        padding-left: 0;
                        padding-right: 0;
                      }
                    }
                  }
                }
                @media (max-width: ${BREAKPOINT.MD}) {
                  > div {
                    > div:first-child {
                      margin: 0 auto;
                      padding-right: 15px;
                      height: 255px;
                    }
                    .HeroContent {
                      padding: 0 15px 40px;
                      margin: 0 auto;
                    }
                  }
                }
              `}
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    img {
                      width: 100%;
                    }
                    .PromoFrameContainer {
                      width: 100% !important;
                    }
                    div[class*='Promo__PromoFrameContainer'] {
                      width: fit-content !important;
                      height: max-content;
                      &:after {
                        top: 35px;
                        left: -30px;
                        border: 20px solid #ff94ca;
                        @media (max-width: ${BREAKPOINT.MD}) {
                          top: 25px;
                          height: 176px;
                          max-width: 300px;
                        }
                      }
                      img {
                        height: 296px;
                        @media (max-width: ${BREAKPOINT.LG}) {
                          height: max-content;
                        }
                        @media (max-width: ${BREAKPOINT.MD}) {
                          height: 169px !important;
                          max-width: 300px !important;
                        }
                      }
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-start;
                      div[class*='Promo__PromoFrameContainer'] {
                        max-width: 514px !important;
                        min-width: fit-content !important;
                        height: 290px;
                      }
                    }
                  `}
                >
                  <PromoSection>
                    <Promo
                      frameImage={
                        <img
                          src={interestFreeHeroimage}
                          alt="interest Free Hero image"
                        />
                      }
                      verticalAlignContent
                      frameAlignment="left"
                      frameBorderColor="#FF94CA"
                    />
                  </PromoSection>
                </div>
              }
              title={
                <>
                  <div
                    css={`
                      color: ${COLOR.WHITE};
                      @media (max-width: ${BREAKPOINT.MD}) {
                        margin-top: 20px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        display: block;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 48px;
                        line-height: 56px;
                      }
                    `}
                  >
                    Get interested in Interest Free
                  </div>
                </>
              }
              text={
                <>
                  <span
                    css={`
                      color: ${COLOR.WHITE};
                    `}
                  >
                    Give your customers more time to pay with long-term Interest
                    Free payments plans available through participating Latitude
                    credit cards.
                  </span>
                </>
              }
              buttonProps={{
                href:
                  'https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=15371734285841',

                children: 'Get in touch',
                trackEventData: {
                  category: 'button-link',
                  action: 'external-link',
                  location: 'hero'
                }
              }}
              footnote={
                <>
                  <span
                    css={`
                      color: ${COLOR.WHITE};
                    `}
                  >
                    ^All information listed on this page is intended for
                    merchant purposes only.
                  </span>
                </>
              }
            />
          </div>
        )}

        <div className="d-none d-lg-block">
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            ctaText="Get in touch"
            ctaHref="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=15371734285841"
            trackId="sticky-navigation-get-in-touch"
            trackEventData={{
              category: 'button-link',
              action: 'external-link',
              location: 'sticky'
            }}
            css={`
              background-color: #fff;
              .sticky-navigation__cta {
                border: 0 !important;
              }
            `}
          />
        </div>

        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="about-latitude-intrest-free"
          css={`
            background-color: red;
            padding-top: 0;
            background-color: #fff;

            h4 {
              max-width: 815px;
              margin: 40px auto 45px;
            }

            @media (min-width: ${BREAKPOINT.LG}) {
              padding-top: 30px;
            }
          `}
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          className="why-choose"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
        />

        <PartneringBenifits />

        <Section id="partners" css="background-color:#fff;">
          <Box
            padding="38px"
            isResponsive
            css={`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <div
              css={`
                width: 100% !important;
                text-align: center;
                @media (min-width: ${BREAKPOINT.MD}) {
                  text-align: left;
                }

                h4 {
                  text-align: center;
                  @media (min-width: ${BREAKPOINT.MD}) {
                    text-align: left;
                  }
                }

                @media (min-width: ${BREAKPOINT.MD}) {
                  width: 100% !important;
                }

                .styled-button {
                  margin: 60px auto;
                  width: auto;
                  display: inline-block;

                  @media (min-width: ${BREAKPOINT.MD}) {
                    margin: 60px 0;
                  }
                }
              `}
            >
              <Box
                css={`
                  text-align: center;
                `}
              >
                <Heading4
                  color="#000000"
                  css={`
                    text-align: center !important;
                  `}
                >
                  Our Partners
                </Heading4>
                <p
                  css={`
                    @media (min-width: ${BREAKPOINT.MD}) {
                      padding-bottom: 30px;
                    }
                    font-size: 18px;
                  `}
                >
                  We keep good company, so should you.{' '}
                  <Link
                    href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=15371734285841"
                    title="Get in touch"
                    target="_blank"
                    trackEventData={{
                      category: 'text-link',
                      action: 'external-link',
                      location: 'our partners'
                    }}
                  >
                    Get in touch{' '}
                  </Link>{' '}
                  today.
                </p>
              </Box>
            </div>

            <div
              css={`
                display: flex;
                width: 100% !important;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 30px;

                @media (min-width: ${BREAKPOINT.MD}) {
                  width: 100% !important;
                }

                a {
                  align-content: center;
                  margin-right: 20px;
                  margin-bottom: 20px;
                  padding: 10px 40px;
                  height: 48px;
                  background: #fff;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
                  border-radius: 4px;
                  align-self: flex-end;
                  display: flex;
                  justify-content: center;
                  cursor: default;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    width: 100% !important;
                  }

                  :first-of-type {
                    margin-top: 50px;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      margin-top: 0;
                    }
                  }
                  img {
                    align-self: center;
                  }
                  img[alt='Good Guys'] {
                    height: 26px;
                  }
                }
              `}
            >
              <Link noStyle>
                <img src={goodguys} alt="Good Guys" />
              </Link>
              <Link noStyle>
                <img src={jbhifi} alt="JB Hifi" />
              </Link>
              <Link noStyle>
                <img src={amart} alt="Amart" />
              </Link>
              <Link noStyle>
                <img src={apple} alt="Apple" />
              </Link>

              <Link noStyle>
                <img src={freedom} alt="Freedom Furniture" />
              </Link>
              <Link noStyle>
                <img src={samsung} alt="Samsung" />
              </Link>
              <Link noStyle>
                <img src={michaelhill} alt="Michael Hill" />
              </Link>
              <Link noStyle>
                <img src={luxuryescapes} alt="Luxury Escapes" />
              </Link>
              <Link noStyle>
                <img src={snooze} alt="snooze" />
              </Link>
            </div>
            <div
              css={`
                text-align: center;
                width: 100%;
              `}
            >
              <Link
                href="/interest-free/find-a-store/"
                trackEventData={{
                  category: 'text-link',
                  action: 'external-link',
                  location: 'Our partners'
                }}
              >
                Check out the full list
              </Link>
            </div>
          </Box>
        </Section>

        <FeaturesStaggeredSlider
          id="why-us"
          css={`
            background-color: #f7f7f7;

            a[class^='Linkstyled__StyledLink-']:hover {
              cursor: default !important;
              border-bottom: 1px solid #006aff !important;
            }
          `}
          data={PageData.features}
          className="whats-important"
          heading="Why Latitude?"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />

        <div
          css={`
            div + div {
              padding-bottom: 0 !important;
            }

            div[class*='Callout__CalloutInner'] h3,
            div[class*='Callout__CalloutInner'] p {
              color: #fff !important;
            }

            .callout {
              background-color: #0555c8;
              background-image: url(${calloutLeftBg}), url(${calloutRightBg});
              background-position: left top, right 122%;
              background-repeat: no-repeat, no-repeat;
              height: 310px;
              display: flex;
              align-items: center;

              @media (max-width: ${BREAKPOINT.MD}) {
                height: 383px;
                background-image: url(${calloutMobileBg});
                background-position: bottom center;
                align-items: flex-start;
              }
            }
          `}
        >
          <BrandedCallout
            confettiBackground="get-in-touch-callout.webp"
            hasConfettiBackground
            heading="Get in touch"
            line1="Send us an enquiry via the get in touch button and"
            line2="we’ll respond within three business days."
            cta={
              <Link
                trackId="get-in-touch"
                href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=15371734285841"
                variant="secondary"
                css="margin-top:16px;"
                button={BUTTON_STYLE.PRIMARY}
                trackEventData={{
                  category: 'button-link',
                  action: 'external-link',
                  location: 'get in touch'
                }}
              >
                Get in touch
              </Link>
            }
          />
        </div>

        <Box.Section
          id="faq"
          css={`
        background-color: ${COLOR.WHITE};
        @media (min-width: ${BREAKPOINT.LG}) {
          background-color: ${COLOR.WHITE};
          width:50%;
        `}
        >
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            Frequently Asked Questions
          </Heading4>
          <AnalyticsLocationProvider location="frequently asked questions">
            <Accordion
              css={`
                .accordion__item-body {
                  padding: 16px 24px;
                  background-color: #f8f8f8 !important;
                }
              `}
              titleBgColor="grey-light"
              items={PageData.faq}
            />
          </AnalyticsLocationProvider>
        </Box.Section>

        <ImportantInformation
          css={`
            background-color: #fff;
            padding-top: 0 !important;
            .important-info {
              svg {
                display: none;
              }
              .row div p.important-info__text {
                text-align: center;
              }
            }
          `}
          data={require('../../data/pages/interest-free/business.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
          additionalPaymentsDisclaimerOne
        />
      </main>
    </Layout>
  );
};

export default BusinessIFPage;
